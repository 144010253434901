import React from 'react';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section>
      {helmet || ''}
      <header className="section">
        <h1 className="w-2/3 title">{title}</h1>
        <p className="w-2/3 subtitle">{description}</p>
      </header>

      <section className="section bg-gray-100 ">
        <div className="content w-2/3">
          <PostContent content={content} />
        </div>
      </section>

      {tags?.length && (
        <section className="section">
          <header className="section-sm-y">
            <h4>Tags</h4>
          </header>
          <ul className="mt-2 flex">
            {tags.map((tag) => (
              <li key={`${tag}tag`} className="mr-4">
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </section>
      )}
    </section>
  );
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const helmet = (
    <Helmet titleTemplate="%s | Blog">
      <title>{`${post.frontmatter.title}`}</title>
      <meta name="description" content={`${post.frontmatter.description}`} />
    </Helmet>
  );

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={helmet}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
