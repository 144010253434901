import React from 'react';
import clsx from 'clsx';

export interface ContentProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string;
}

export const HTMLContent: React.FC<ContentProps> = ({ content, className }) => {
  return (
    <div className={clsx('content', className)} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

const Content = ({ content, className }) => <div className={className}>{content}</div>;

export default Content;
